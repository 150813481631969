<template>
  <div>
    <v-row>
      <v-col>
        <v-card outlined>
          <v-card-title>
            Pedido de Compra #{{ purchase_order.code }}
          </v-card-title>

          <v-card-subtitle>
            <b>Empresa: </b>{{ purchase_order.company.name }}
            <br />
            <b> Fornecedor: </b>{{ purchase_order.supplier.name }}
            <template v-if="purchase_order.scheduled_delivery_at">
              <br />
              <b> Previsão de entrega: </b>
              {{ $format.dateBr(purchase_order.scheduled_delivery_at) }}
            </template>

            <template v-if="purchase_order.notes">
              <br />
              <b>Observações:</b>
              {{ purchase_order.notes }}
            </template>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>

    <h3 class="my-6">Produtos</h3>
    <v-row>
      <v-col class="text-right">
        <v-data-table
          :headers="headers"
          :items="purchase_order.purchase_order_products"
          :items-per-page="-1"
          hide-default-footer
          disable-sort
          dense
        >
          <template v-slot:[`item.product`]="{ item }">
            <span v-if="item.product_id">{{ item.product.name }} </span>
            <span v-if="!item.product_id">{{ item.product_name }} </span>
          </template>

          <template v-slot:[`item.quantity`]="{ item }">
            {{ $format.decimal(item.quantity) }}
          </template>
          <template v-slot:[`item.quantity_remaining`]="{ item }">
            {{ $format.decimal(item.quantity_remaining) }}
          </template>

          <template v-slot:[`item.received`]="{ item }">
            <app-number-field v-model="item.received" />
          </template>
          <template v-slot:[`item.actions`]="{ index, item }">
            <v-btn @click="receiveAllProduct(index, item)" icon>
              <v-icon>mdi-package-variant-closed-plus</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <v-btn
          @click="receiveAll()"
          class="text-capitalize mt-4"
          text
          color="info"
        >
          Entregar tudo
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <CommentList v-model="purchase_order.comments" />
      </v-col>
    </v-row>

    <v-row class="text-end">
      <v-col>
        <v-btn text class="mr-4" color="secondary" @click="back()">
          Voltar
        </v-btn>
        <v-btn color="primary" @click="receiveProducts()"> Salvar </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import CommentList from "@/components/comments/CommentList.vue";

export default {
  components: {
    CommentList,
  },

  data() {
    return {
      purchase_order: {
        purchase_order_products: [],
        company: {},
        supplier: {},
        comments: [],
      },

      editProductIndex: null,
      headers: [
        { text: "Código", value: "product.code", align: "center", width: "5%" },

        { text: "Descrição", value: "product" },
        {
          text: "Pedido",
          value: "quantity",
          align: "right",
          width: "15%",
        },
        {
          text: "Disponível",
          value: "quantity_remaining",
          align: "right",
          width: "15%",
        },
        {
          text: "Entregue",
          value: "received",
          align: "right",
          width: "15%",
        },
        { text: "", value: "actions", align: "right", width: "5%" },
      ],
      activitiesStatus: {
        created: "Criado",
        updated: "Atualizado",
        deleted: "Deletado",
        restored: "Restaurado",
      },
    };
  },

  computed: {
    hasProducts() {
      return this.purchase_order.purchase_order_products.length > 0;
    },
  },

  created() {
    this.showPurchase();
  },

  methods: {
    showPurchase() {
      this.$loading.start();
      this.$http
        .show("purchase-order/purchase-order", this.$route.params.id)
        .then((response) => {
          this.purchase_order = response.purchase_order;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    receiveAllProduct(index, product) {
      (product.received = product.quantity_remaining),
        this.$set(this.purchase_order.purchase_order_products, index, product);
    },

    receiveAll() {
      this.purchase_order.purchase_order_products.forEach((product) => {
        product.received = product.quantity_remaining;
      });
    },
    receiveProducts(status) {
      this.$loading.start();
      this.$http
        .update(
          "purchase-order/purchase-order/receive",
          this.purchase_order.id,
          this.purchase_order
        )
        .then((response) => {
          this.processSaved();
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    processSaved() {
      this.$loading.finish();
      this.back();
    },

    back() {
      // var history = this.$router.history;
      // history.length > 1 ? history.go(-1) : this.$router.push("/pedidos");

      const purchaseIndex = window.opener;

      purchaseIndex.location.reload();
      if (!this.$app.isMobile) {
        window.close();
      } else {
        this.$router.go(-1);
      }

      //: this.$router.go(-1);
      // window.opener ?

      purchaseIndex.location.reload();
    },
  },
};
</script>

<style>
</style>

